<!-- Cobros / Pagos Detalle -->

<template>
  <div class="cobros_pagos_detalle" v-if="schema"> 
    <div class="contenedor"> 
      <div class="cab">FACTURAS</div>  
      <v-sheet :elevation="4">

        <!-- Contenido -->
        <div class="contenedor"> 

          <!-- Filtro de Busqueda -->
          <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div style="display:flex; align-items:center">           
              <v-select  
                style="flex: 0 0 30%"
                v-bind="$select"
                v-model="schema.ctrls.fra_tipo.value"
                :label="schema.ctrls.fra_tipo.label"
                :items="itemsFra_tipo"
                item-value="ope"
                item-text="name"
                @change="filterFra()">
              </v-select>

              <v-select  
                style="flex: 0 0 30%"
                v-bind="$select"
                v-model="schema.ctrls.estado.value"
                :label="schema.ctrls.estado.label"
                :items="itemsEstado"
                item-value="id"
                item-text="name"
                @change="filterFra()">
              </v-select>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.txt.value"
                :label="schema.ctrls.txt.label"
                append-icon="mdi-magnify"
                @click:append="filterFra()"
                @keypress.13="filterFra()">
              </v-text-field>
            </div>
          </div>

          <!-- Grid -->
          <base_Fgrid 
            v-model="selected"
            :padre="stName" 
            :Entorno="Entorno" 
            hide-default-footer
            dense
            show-select
            fixed-header
            :items-per-page=-1     
            @onEvent="event_Grid">

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ }">
              <div class="conflex pt-2" style="justify-content:space-between;width:480px">

                <btraextra
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"                  
                  btra_modulo="standard"
                  @onEvent="event_btra_Top">
                </btraextra>

              </div>
            </template>


            <!-- Slot campos -->
            <template v-slot:rev="{ Sitem }">
              {{ Sitem.rev_fh==''? '' : 'R' }}
            </template>
          </base_Fgrid> 

          <div class="conflex" style="padding-top:20px">
            <v-btn               
              v-bind="$cfg.btn.busca_with_text"             
              @click="revisarFras"
              :disabled="!selected.length || selected[0].tipo_ope!='C'"
              title="Revisar Facturas">
                Revisada
            </v-btn>  

            <v-btn               
              v-bind="$cfg.btn.busca_with_text"             
              @click="parcialFras"
              :disabled="selected.length!=1"
              title="Pago/Cobro parcial de Facturas">
                Parcial
            </v-btn>  

            <cobros_pagos_liquidarFras
              :padre="stName"
              :accionRow="auxRecord"
              :auxRecord="selected"
              :tipo="tipo_liquidacion"
              :total="schema.ctrls.diferencia.value"
              @onEvent="$event.accion=='reloadFras'? reloadFras():''">
            </cobros_pagos_liquidarFras>
            
            <vllabel
              style="width:180px;padding-left:10px"  
              :label="schema.ctrls.diferencia.label"            
              :txt="schema.ctrls.diferencia.value | num(2)">
            </vllabel>            
          </div>   

        </div>
      </v-sheet>
    </div>
        
    

      <!-- Componentes diámicos -->
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado" v-if="selected.length">
            <component                 
                :is="componente_dinamico"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :datos_complementarios="{}"
                :auxRecord="auxRecord"
                :filtro="filtroFacturacion"
                :componenteTipo="componente_dinamico=='fras_Exp_F'? 'FC' : 'M'"
                :disparo="disparo" 
                :disparoFinder="disparo"               
                @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''"
                @update_parcial="update_parcial">
            </component>           
          </div>
      </v-dialog> 
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs"; 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const exp_M = () => plugs.groute("exp_M.vue", "comp");
  const exp_pres_M = () => plugs.groute("exp_pres_M.vue", "comp");
  const fras_Exp_F = () => plugs.groute("fras_Exp_F.vue", "comp");
  const cobros_pagos_liquidarFras = () => plugs.groute("cobros_pagos_liquidarFras.vue", "comp");
  const cobros_pagos_parcialFras = () => plugs.groute("cobros_pagos_parcialFras.vue", "comp");
 
  export default {
    mixins: [mixinFinder],
    components: { base_Fgrid, btraextra, exp_M, exp_pres_M, fras_Exp_F, cobros_pagos_liquidarFras, cobros_pagos_parcialFras },
    props: {
      padre: { type:String, default: ''},
      id: { type:[Number, String], default: 0},   
      auxRecord: { type:Object, default: null},     
      disparoFinder: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },
  

    data() {
      return {  
        schema:null,
        api:'cobros_pagos_detalle',
        stName:'stFcobros_pagos_detalle',
        accion:"0",
        accionRow:null,
        selected:[], 
        tipo_liquidacion:0,
        filtroFacturacion:'',
              
        headers: {},        
        itemsFra_tipo: [],
        itemsEstado: [],

        // para lanzar exp_pres_m
        datos_nuevo_presupuesto:{},
        componente_dinamico:null,
        modal:false,
        disparo:false,
      };
    },

        
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.header = false;
        this.Entorno.grid.autoload= false;
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        this.Entorno.btra.footer.extra = [
          { accion: "expedientes", icono: "mdi-alpha-e-circle-outline", title:'Ver/Editar Expediente' },
          { accion: "facturacion", icono: "mdi-alpha-f-circle-outline", title:'Administración Facturas' },
          //{ accion: "ver_fra", icono: "mdi-alpha-f-circle-outline", title:'Ver/Editar Presupuesto/Factura' },          
          { accion: "pdf", btn: "btn_pdf" },
        ];

        this.headers = {
          header: [            
            { text: "T", value: "tipo_fra", width: "5%" },
            { text: "Factura", value: "fra_doc", width: "15%" },
            { text: "Fecha", value: "fra_fh", width: "15%", filtro:"fecha" },
            { text: "Vencimiento", value: 'fra_vto',  width: "15%", filtro: "fecha" },
            { text: "Importe", value: "imp", width: "15%", filtro:'num', arg:2 },
            { text: "Pendiente", value: "pte", width: "15%", filtro:'num', arg:2 },
            { text: "Control", value: "control", width: "15%" },
            { text: "*", value: "rev", width: "5%", slot:true },
          ],

        };

        this.Entorno.grid.headers = this.set_headers();

        // items adicionales
        // array de tipo de facturas
        this.itemsFra_tipo= [
          { id:"0", name:"(Tipo)", ope:"VCG" },         
          { id:"1", name:"Ventas", ope:"V" },
          { id:"2", name:"Compras", ope:"C" },          
          { id:"3", name:"Gastos", ope:"G" }
        ];

        // array de estado de facturas
        this.itemsEstado= [
          { id:"0", name:"(Estado)" },
          { id:"1", name:"Revisadas" },
          { id:"2", name:"Sin revisar" }
        ];

      },   

      
      // configuro estado botones
      botoneras_set() {
        this.btnSet("pdf",{ disabled:this.selected.length? this.selected[0].tipo_ope!='V' || this.selected.length>1 : true });
        this.btnSet("expedientes",{ disabled:this.selected.length!=1? true : false });
        this.btnSet("facturacion",{ disabled:this.selected.length!=1? true : false });
        /* this.btnSet("ver_fra",{ disabled:this.selected.length!=1? true : false }); */
        this.btnSet("ver_fra",{ disabled:true });
      },


      // acciones antes de 'get_records'
      ini_component() {
        this.schema.ctrls['tip'].value= this.miStPadre.schema.ctrls.tip.value;
        this.schema.ctrls['vto'].value= this.miStPadre.schema.ctrls.vto.value;
        this.schema.ctrls['fh'].value= this.miStPadre.schema.ctrls.fh.value;
        this.schema.ctrls['tipo_cta'].value= this.auxRecord.tipo_cta;
        this.schema.ctrls['cta_id'].value= this.auxRecord.cta_id;
       
        // obtengo facturas
        this.get_records('ini');

        // personalizo botonera
        this.botoneras_set();

        // limpio selected
        this.selected= [];         
      },


      // selecciono la primera linea del Grid y recalculo campos compra / venta
      get_records_fin() {        
        //filtramos
        this.filterFra();       

      },


      // muestro ventana expedientes
      expedientes() {
        if (this.selected.length!= 1 ) {
          this.$root.$alert.open('Seleccione sólo un Proveedor', 'info');
          return;
        }
      
        this.componente_dinamico= "exp_M";
        this.accionRow= { id:this.selected[0].exp_id };
        this.disparo= !this.disparo;
        this.modal= true;
      },


      // ver módulo presupuesto/factura
      ver_fra() {
  
        this.componente_dinamico= "exp_pres_M";
        //this.accionRow= { id:this.selected[0].exp_id };
        this.disparo= !this.disparo;
        this.modal=true;
      },


      // ver módulo Facturación
      facturacion() {
        this.filtroFacturacion= "#" + this.selected[0].exp_id;
        this.componente_dinamico= "fras_Exp_F";
        this.disparo= !this.disparo;
        this.modal=true;
      },


      //
      async pdf() {                
        let args = { tipo: 'fnc', accion: 'expediente', fn_args: { accion: "factura", id: this.selected[0].id, opc: 1 }}; 
          console.log('args: ', args);
        // llamada a la API          
        var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('apiResult: ', apiResult);
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // abro documento
        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");    
      },


      // filtro fras
      filterFra() {
        var r= this.miSt.read_records.slice().filter(elem=> {
          
          return (
            this.schema.ctrls.fra_tipo.value.includes(elem.tipo_ope) &&
            (elem.fra_doc.toUpperCase().includes(this.schema.ctrls.txt.value.toUpperCase()) ||
             elem.control.toUpperCase().includes(this.schema.ctrls.txt.value.toUpperCase()) ) &&
            (this.schema.ctrls.estado.value=='0'? (elem.rev_fh=='' || elem.rev_fh!='') :
            this.schema.ctrls.estado.value=='1'? elem.rev_fh!='' : elem.rev_fh=='')
          )

        });
        
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:r });       
      },


      //
      async fusion(tipo_ope, suplido, fusion=false, funerariaN=0){
         
          //cab almacena valores de cabecera para pase
 
          let cab={tipo_ope:tipo_ope, suplido:suplido, tipo_cta:'0', cta_id:'0'};
          let lins=[];
          // lins almacena lineas de fusion
         


          // Venta
          if (cab.tipo_ope=="V"){
            cab.tipo_cta=this.auxRecord.callemp_tp
            cab.cta_id=this.auxRecord.callemp
          } 
         
          // COMPRA
          if (cab.tipo_ope=="C" && funerariaN==0){
            //nada            
          }

          //COMPRA SUPLIDO
           if (cab.tipo_ope=="C" && cab.suplido==0 && !fusion){
             // Nada
          }
          
       
        this.datos_nuevo_presupuesto={cab,lins}
    
        this.accion= "1";
        this.modal= true;
        this.disparoPresup= !this.disparoPresup;
      },


      // calcula total diferencia fras seleccionadas
      calcular_total() {        
        this.schema.ctrls.diferencia.value= "";
        this.schema.ctrls.diferencia.label= 'TOTAL';
        this.tipo_liquidacion= 0;

        let totc= 0, totv= 0;      
        this.selected.map(elem=> {           
          if (elem.tipo_ope=='C') totc+= Number(elem.pte);
          if (elem.tipo_ope=='V') totv+= Number(elem.pte);
        });
        console.log('totales: ', totc, totv);
        if (totc== totv) { this.schema.ctrls.diferencia.value= 0; return; }
          
        if (totc> totv) {
          this.schema.ctrls.diferencia.value= totc - totv;
          this.schema.ctrls.diferencia.label= 'PAGO';
          this.tipo_liquidacion= 1;
          return;
        }

        this.schema.ctrls.diferencia.value= totv - totc;
        this.schema.ctrls.diferencia.label= 'COBRO'
        this.tipo_liquidacion= 2;
      },


      // recargo finder fras
      reloadFras() {
        this.ini_component();
        this.calcular_total();
      },


      // revisar Facturas
      revisarFras() {          
        if (this.selected.length!= 1) { this.$root.$alert.open('Debe seleccionar sólo una Factura', 'error'); return; }
        if (this.selected[0].tipo_ope!= 'C') { this.$root.$alert.open('Debe seleccionar sólo una Factura', 'error'); return; }

        
        let msg='';
        let rev=1;
        if (this.selected[0].rev_usu!= '') {
          //this.$root.$alert.open('Factura ya revisada.<br>Usuario: '+this.selected[0].rev_usu+' - Fecha: '+this.selected[0].rev_fh, 'error');
          //return;
          let fh= new Date(this.selected[0].rev_fh).toLocaleDateString('es-Es');                  
          rev=0;
          msg='Factura ya revisada.<br>Usuario: <b>'+this.selected[0].rev_usu+'</b> - Fecha: <b>'+ fh + "</b><br><br>" +
            '¿ MARCAMOS COMO NO REVISADA ?'; 
        } else 
          msg='¿ MARCAMOS COMO REVISADA ?';

        this.$root.$alert.open(msg  , 'confirm')
        .then(r=> { if (r) this.revisarFras_continue(rev) }
        );        
      },

      async revisarFras_continue(rev) {
        let args = { tipo: 'fnc', accion: 'facturas', fn_args: { accion: "revisar", id: this.selected[0].id, rev: rev }}; 
        
        // llamada a la API          
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // actualizo item finder facturas con el recibido
        this.selected[0]= apiResult.r[0];
        this.$store.commit(this.stName + '/update_record', { opcion: 'guardar', record: apiResult.r[0], id: apiResult.r[0].id });
      },


      // 
      cerrar_dinamico() {
        // obtengo los avisos de expedientes
        this.modal= false;
        this.componente_dinamico= null;
      },

        
      // muestro ventana de pagos/cobros parciales
      parcialFras() {
        this.accionRow= this.selected[0];
        this.componente_dinamico="cobros_pagos_parcialFras";
        this.modal=true;
      },


      // entra al guardar un cobro parcial
      update_parcial(evt) {        
        this.selected[0]= evt.record
        this.calcular_total();
      }


    },


    watch: {
      selected() {        
        this.botoneras_set();
        this.calcular_total();
      }
    }
  }
</script>